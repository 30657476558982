import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import API from '../../api/API';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  MiniMap,
  Node,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { displayFormatedDateAndTime } from '../../utils';
import {
  edgeTypes,
  nodeTypes,
} from '../../pages/Settings/Workflows/components/WorkflowCanvas';
import FloatingConnectionLine from '../../pages/Settings/Workflows/components/edges/FloatingEdge/FloatingConnectionLine';
import WorkflowExecutionContext from '../../contexts/WorkflowExecutionContext';
import { ResetWorkflowConfirmationModal } from '../ResetWorkflowConfirmationModal';
import { EntityName, Execution, Workflow } from '../../models/workflow';
import WorkflowContext from '../../contexts/WorkflowContext';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

export interface SeekMutationVariables {
  nodeTargetId: string;
}

interface Props {
  triggerId: string;
  targetCuid: string;
}

export default function WorkflowExecutionsViewer({
  triggerId,
  targetCuid,
}: Props) {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [showExecutionLogTab, setShowExecutionLogTab] = useState(false);
  const resetWorkflowModal = useDisclosure();
  const [execution, setExecution] = useState<Execution | undefined>();
  const [workflow, setWorkflow] = useState<Workflow | undefined>();

  const { isLoading, refetch } = useQuery(
    [
      'workflows',
      'targets',
      targetCuid,
      'executions',
      ['InventoryModel.created'],
    ],
    async () => {
      return API.GetWorkflowExecutionsForTarget(targetCuid, ['active']);
    },
    {
      onSuccess: data => {
        if (data) {
          const e = data.find(
            (e: Execution) => e.workflow.trigger_id === triggerId,
          );
          if (e) {
            const nodes = e.workflow.version.source.nodes.map((node: Node) => {
              node.data.execution = execution;
              if (node.id === e.current_node) {
                return {
                  ...node,
                  className: 'current-workflow-node',
                };
              }
              return node;
            });
            setNodes(nodes || []);
            setEdges(e.workflow.version.source.edges || []);
            setWorkflow(e.workflow);
            setExecution(e);
          } else {
            setNodes([]);
            setEdges([]);
            setWorkflow(undefined);
            setExecution(undefined);
            console.error('No V2 execution found with triggerId', triggerId);
          }
        }
      },
    },
  );

  useQuery(
    ['workflows', 'targets', targetCuid, 'available', 'ui:execution-viewer'],
    async () => {
      return API.GetAvailableWorkflowsForTarget(
        targetCuid,
        EntityName.INVENTORY_MODEL,
      );
    },
    {
      onSuccess: data => {
        if (data) {
          setWorkflow(data[0]);
        }
      },
    },
  );

  const seek = useMutation(
    [],
    async ({ nodeTargetId }: SeekMutationVariables) => {
      return await API.WorkflowSeekTo(
        workflow!.cuid,
        execution!.cuid,
        nodeTargetId,
      );
    },
    {
      onSuccess: () => {
        refetch();
        toast({
          variant: 'subtle',
          title: 'Workflow execution seeked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: error => {
        if (error instanceof Error) {
          toast({
            variant: 'subtle',
            title: 'Error seeking workflow execution',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    },
  );

  const resumeWait = useMutation(
    [],
    async () => {
      return await API.WorkflowResumeWait(workflow!.cuid, execution!.cuid);
    },
    {
      onSuccess: () => {
        refetch();
        toast({
          variant: 'subtle',
          title: 'Workflow execution seeked successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const startExecution = useMutation(
    async () => {
      return API.StartWorkflowExecution(workflow?.cuid!, targetCuid, '');
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    },
  );

  return (
    <>
      <Button
        variant={'ghost'}
        onClick={onOpen}
        leftIcon={<Icon as={ArrowsRightLeftIcon} />}
        data-testid="see-workflow-btn"
      >
        See Workflow
      </Button>
      <Button
        variant={'ghost'}
        onClick={() => resetWorkflowModal.onOpen()}
        leftIcon={<Icon as={ArrowsRightLeftIcon} />}
        data-testid="see-workflow-btn"
        hidden={!execution}
      >
        Reset Workflow
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent
          bg="neutral.50"
          data-testid="workflow-modal"
          h="95vh"
          maxHeight="95vh"
          width="100%"
          maxW="95%"
        >
          {isLoading ? (
            <Progress isIndeterminate={true} />
          ) : (
            <>
              {execution ? (
                <>
                  <ModalHeader>{execution.workflow.title}</ModalHeader>
                  <ModalCloseButton data-testid="close-workflow-modal-btn" />

                  <ModalBody>
                    <Tabs size="md" colorScheme="brand">
                      {showExecutionLogTab && (
                        <TabList>
                          <Tab
                            onDoubleClick={() =>
                              setShowExecutionLogTab(!showExecutionLogTab)
                            }
                          >
                            Workflow
                          </Tab>
                          <Tab>Execution Log</Tab>
                        </TabList>
                      )}

                      <TabPanels>
                        <TabPanel>
                          <Box
                            h={'calc(100vh - clamp(100px, 18vh, 180px ))'}
                            w={'full'}
                          >
                            <WorkflowContext.Provider
                              value={{ workflow: execution.workflow }}
                            >
                              <WorkflowExecutionContext.Provider
                                value={{
                                  seek,
                                  resumeWait,
                                }}
                              >
                                <ReactFlow
                                  nodes={nodes}
                                  edges={edges}
                                  nodeTypes={nodeTypes}
                                  edgeTypes={edgeTypes}
                                  connectionLineComponent={
                                    FloatingConnectionLine
                                  }
                                  fitView
                                  fitViewOptions={{
                                    nodes: nodes.filter(
                                      node =>
                                        node.className ===
                                        'current-workflow-node',
                                    ),
                                  }}
                                  minZoom={0.2}
                                  maxZoom={1}
                                >
                                  <Background />
                                  <MiniMap />
                                  <Controls />
                                </ReactFlow>
                              </WorkflowExecutionContext.Provider>
                            </WorkflowContext.Provider>
                          </Box>
                        </TabPanel>
                        <TabPanel>
                          <Accordion allowToggle>
                            {(execution?.steps || []).map((step, index) => (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                      {displayFormatedDateAndTime(
                                        step.created_at,
                                      )}{' '}
                                      - {step.ran_by?.name || 'user'} triggered{' '}
                                      {step.execution_data.trigger} -{' '}
                                      {step.tags.join(', ')}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <pre>
                                    {JSON.stringify(
                                      step.execution_data,
                                      null,
                                      2,
                                    )}
                                  </pre>
                                </AccordionPanel>
                              </AccordionItem>
                            ))}
                          </Accordion>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </ModalBody>
                </>
              ) : (
                <ModalBody>
                  <Text>There are no executions for: {workflow?.title}</Text>
                  <Button
                    onClick={() => startExecution.mutate()}
                    isLoading={startExecution.isLoading}
                  >
                    Start execution now
                  </Button>
                </ModalBody>
              )}
            </>
          )}
        </ModalContent>
      </Modal>

      {workflow && execution && (
        <ResetWorkflowConfirmationModal
          isOpen={resetWorkflowModal.isOpen}
          onClose={resetWorkflowModal.onClose}
          onConfirm={async () => {
            await API.PostWorkflowExecutionAbort(
              workflow.cuid,
              execution.cuid,
              true,
            );
            resetWorkflowModal.onClose();
            // Reload the page
            window.location.reload();
          }}
        />
      )}
    </>
  );
}
