import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Checkbox,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import API from '../../api/API';

import {
  DocumentArrowDownIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';
import MoreInfoPopOver from '../MoreInfoPopOver';
import { TInventoryModel } from '../../models/inventory_model';
import { CONFIG } from '../../config';
import AdminAPI from '../../api/AdminAPI';

interface DownloadModalProps {
  inventoryModel: TInventoryModel;
}

const DownloadModelDocumentationModal = ({
  inventoryModel,
}: DownloadModalProps) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [format, setFormat] = useState('docx');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState('');
  const [disclaimerText, setDisclaimerText] = useState<string | null>(null);
  const ref = useRef<HTMLAnchorElement | null>(null);

  const fetchDisclaimerText = async () => {
    try {
      const settings = await AdminAPI.GetGeneralSettings();
      if (settings.custom_texts?.documentation_export_disclaimer) {
        setDisclaimerText(
          settings.custom_texts.documentation_export_disclaimer,
        );
      } else {
        setDisclaimerText(null);
      }
    } catch (error) {
      // Silently fail and don't show a disclaimer
      console.error('Failed to fetch disclaimer text:', error);
      setDisclaimerText(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDisclaimerText();
      setError(''); // Clear any previous error when the modal is opened
    }
  }, [isOpen]);

  const getFilename = () => {
    const date = new Date();
    const dateString = date.toISOString().substring(0, 10);
    return `${inventoryModel.name.replaceAll(
      ' ',
      '',
    )}_${dateString}-documentation.${format}`;
  };

  const downloadDocument = async () => {
    try {
      setError('');
      setDownloading(true);
      let response;
      response = await API.DownloadDocumentationReport(
        inventoryModel,
        format,
        getFilename(),
      );

      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(blobUrl);

      // ref.current?.click(); can run before downloadFilename new state is ready
      setTimeout(() => {
        ref.current?.click();
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message as string);
      }
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <a href={downloadUrl} hidden ref={ref} download={getFilename()} />
      <Button
        variant={'ghost'}
        onClick={onToggle}
        leftIcon={<Icon as={ArrowDownTrayIcon} boxSize={5} />}
      >
        Export Document
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Export Model Documentation
            <MoreInfoPopOver
              title="Export Document"
              link={`${CONFIG.VALIDMIND_DOCS_URL}/guide/model-documentation/export-documentation.html`}
              placement="bottom"
              iconProps={{
                ml: 1,
              }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Box>
                <Text>Choose the file format that works best for you.</Text>
              </Box>
              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  1. Pick a file format
                </Text>
              </Box>

              <Box pl={5}>
                <RadioGroup defaultValue="docx" onChange={setFormat}>
                  <Stack>
                    <Radio value={'docx'}>
                      Microsoft Word (<Badge>.docx</Badge>)
                    </Radio>
                    {/* <Radio value={'pdf'} >
                      Portable Document Format (.pdf)
                    </Radio> */}
                  </Stack>
                </RadioGroup>
              </Box>

              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  2. Download your file
                </Text>
              </Box>
              {format === 'docx' && (
                <Box pl={5}>
                  <Text>
                    To generate the table of contents, click <Badge>Yes</Badge>{' '}
                    when prompted to update the fields in the document.
                  </Text>
                  <Spacer h={8} />
                  <Text textAlign={'center'}>
                    <strong>{getFilename()} </strong>is ready to download!
                  </Text>
                </Box>
              )}
              {error && (
                <Box>
                  <Alert status="error" variant="subtle">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>
                        Something went wrong configuring your download
                      </AlertTitle>
                      <AlertDescription>
                        Please refresh the page and try to export the model
                        documentation again.
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter flexDirection="column" alignItems="stretch" gap={4}>
            {disclaimerText && (
              <Alert
                status="info"
                variant="subtle"
                borderRadius="md"
                colorScheme="brandSecondary"
                display="flex"
                alignItems="flex-start"
                p={4}
              >
                <AlertIcon alignSelf="flex-start" mt={0.5} />
                <Box>
                  <AlertDescription
                    fontSize="sm"
                    fontWeight="medium"
                    lineHeight="tall"
                    whiteSpace="pre-wrap"
                  >
                    {disclaimerText}
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            <Flex justifyContent="flex-end">
              <Button
                isLoading={downloading}
                leftIcon={<Icon as={DocumentArrowDownIcon} boxSize={5} />}
                onClick={downloadDocument}
                variant={'primary'}
              >
                Download File
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DownloadValidationReportModal = ({
  inventoryModel,
}: DownloadModalProps) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [includeComplianceSummary, setIncludeComplianceSummary] =
    useState(false);
  const [includeGuidelines, setIncludeGuidelines] = useState(false);
  const [includeGuidelineAdherence, setIncludeGuidelineAdherence] =
    useState(false);
  const [format, setFormat] = useState('docx');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState('');
  const [disclaimerText, setDisclaimerText] = useState<string | null>(null);
  const ref = useRef<HTMLAnchorElement | null>(null);

  const fetchDisclaimerText = async () => {
    try {
      const settings = await AdminAPI.GetGeneralSettings();
      if (settings.custom_texts?.documentation_export_disclaimer) {
        setDisclaimerText(
          settings.custom_texts.documentation_export_disclaimer,
        );
      } else {
        setDisclaimerText(null);
      }
    } catch (error) {
      // Silently fail and don't show a disclaimer
      console.error('Failed to fetch disclaimer text:', error);
      setDisclaimerText(null);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDisclaimerText();
      setError(''); // Clear any previous error when the modal is opened
    }
  }, [isOpen]);

  const getFilename = () => {
    const date = new Date();
    const dateString = date.toISOString().substring(0, 10);
    return `${inventoryModel.name.replaceAll(
      ' ',
      '',
    )}_${dateString}-validation_report.${format}`;
  };

  const downloadDocument = async () => {
    try {
      setError('');
      setDownloading(true);
      let response;
      response = await API.DownloadValidationReport(
        inventoryModel,
        includeComplianceSummary,
        includeGuidelines,
        includeGuidelineAdherence,
        format,
        getFilename(),
      );

      const blobUrl = URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(blobUrl);

      // ref.current?.click(); can run before downloadFilename new state is ready
      setTimeout(() => {
        ref.current?.click();
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message as string);
      }
    } finally {
      setDownloading(false);
    }
  };

  return (
    <>
      <a href={downloadUrl} hidden ref={ref} download={getFilename()} />
      <Button
        variant={'ghost'}
        onClick={onToggle}
        leftIcon={<Icon as={ArrowDownTrayIcon} boxSize={5} />}
      >
        Export Document
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size={'3xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Export Validation Report
            <MoreInfoPopOver
              title="Export Document"
              link="https://docs.validmind.ai/guide/model-documentation/export-documentation.html"
              placement="bottom"
              iconProps={{
                ml: 1,
              }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  1. Configure what goes into your document
                </Text>
              </Box>

              <Box pl={5}>
                <Checkbox
                  isChecked={includeComplianceSummary}
                  onChange={e => setIncludeComplianceSummary(e.target.checked)}
                >
                  Include compliance summary
                </Checkbox>
              </Box>
              <Box pl={5}>
                <Checkbox
                  isChecked={includeGuidelines}
                  onChange={e => setIncludeGuidelines(e.target.checked)}
                >
                  Include validation guidelines information
                </Checkbox>
              </Box>
              <Box pl={5}>
                <Checkbox
                  isChecked={includeGuidelineAdherence}
                  onChange={e => setIncludeGuidelineAdherence(e.target.checked)}
                >
                  Include validation guideline adherence details
                </Checkbox>
              </Box>
              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  2. Pick a file format
                </Text>
              </Box>

              <Box pl={5}>
                <RadioGroup defaultValue="docx" onChange={setFormat}>
                  <Stack>
                    <Radio value={'docx'}>
                      Microsoft Word (<Badge>.docx</Badge>)
                    </Radio>
                    {/* <Radio value={'pdf'} >
                      Portable Document Format (.pdf)
                    </Radio> */}
                  </Stack>
                </RadioGroup>
              </Box>

              <Box>
                <Text fontSize={'lg'} fontWeight={'semibold'}>
                  3. Download your file
                </Text>
              </Box>
              {format === 'docx' && (
                <Box pl={5}>
                  <Text>
                    To generate the table of contents, click <Badge>Yes</Badge>{' '}
                    when prompted to update the fields in the document.
                  </Text>
                  <Spacer h={8} />
                  <Text textAlign={'center'}>
                    <strong>{getFilename()} </strong>is ready to download!
                  </Text>
                </Box>
              )}
              {error && (
                <Box>
                  <Alert status="error" variant="subtle">
                    <AlertIcon />
                    <Box>
                      <AlertTitle>
                        Something went wrong configuring your download
                      </AlertTitle>
                      <AlertDescription>
                        Please refresh the page and try to export the model
                        documentation again.
                      </AlertDescription>
                    </Box>
                  </Alert>
                </Box>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter flexDirection="column" alignItems="stretch" gap={4}>
            {disclaimerText && (
              <Alert
                status="info"
                variant="subtle"
                borderRadius="md"
                colorScheme="brandSecondary"
                display="flex"
                alignItems="flex-start"
                p={4}
              >
                <AlertIcon alignSelf="flex-start" mt={0.5} />
                <Box>
                  <AlertDescription
                    fontSize="sm"
                    fontWeight="medium"
                    lineHeight="tall"
                    whiteSpace="pre-wrap"
                  >
                    {disclaimerText}
                  </AlertDescription>
                </Box>
              </Alert>
            )}
            <Flex justifyContent="flex-end">
              <Button
                isLoading={downloading}
                leftIcon={<Icon as={DocumentArrowDownIcon} boxSize={5} />}
                onClick={downloadDocument}
                variant={'primary'}
              >
                Download File
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { DownloadModelDocumentationModal, DownloadValidationReportModal };
