import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AdminAPI, {
  Organization,
  Role,
  PatchUserRequest,
} from '../../api/AdminAPI';
import { Select } from 'chakra-react-select';
import { useOrganizations } from '../../hooks/admin';

interface ManageUserRolesModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
  organization: Organization;
}

export function ManageUserRolesModal({
  isOpen,
  onClose,
  user,
  organization,
}: ManageUserRolesModalProps) {
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data: organizations } = useOrganizations();

  // Get fresh organization data with all roles
  const currentOrg = organizations?.find(org => org.cuid === organization.cuid);

  // Initialize selected roles when modal opens
  useEffect(() => {
    const userOrg = user.organizations.find(
      (org: Organization) => org.cuid === organization.cuid,
    );
    if (userOrg) {
      setSelectedRoles(userOrg.roles);
    } else {
      setSelectedRoles([]);
    }
  }, [user, organization, isOpen]);

  const updateUserMutation = useMutation({
    mutationFn: async (patchData: PatchUserRequest) => {
      return AdminAPI.PatchUser(user.cuid, patchData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      toast({
        variant: 'subtle',
        title: 'User roles updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    },
    onError: (error: any) => {
      toast({
        variant: 'subtle',
        title: 'Error updating roles',
        description: error.response?.data?.message || 'An error occurred',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleSubmit = () => {
    const updateData: PatchUserRequest = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      organization: {
        organization_cuid: organization.cuid,
        roles: selectedRoles.map((role: Role) => role.cuid),
      },
    };

    updateUserMutation.mutate(updateData);
  };

  const handleClose = () => {
    // Reset to current roles when closing
    const userOrg = user.organizations.find(
      (org: Organization) => org.cuid === organization.cuid,
    );
    if (userOrg) {
      setSelectedRoles(userOrg.roles);
    } else {
      setSelectedRoles([]);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Roles - {organization.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text>
              Select the roles for this user in {organization.name}. Removing
              all roles will remove the user from this organization.
            </Text>
            <FormControl>
              <FormLabel>Roles</FormLabel>
              <Select
                isMulti
                options={
                  currentOrg?.roles
                    .filter(role => role.scope !== 'Model')
                    .map(role => ({
                      value: role.cuid,
                      label: role.name,
                      data: role,
                    })) || []
                }
                placeholder="Select roles"
                value={selectedRoles.map(role => ({
                  value: role.cuid,
                  label: role.name,
                  data: role,
                }))}
                onChange={selectedOptions =>
                  setSelectedRoles(selectedOptions.map(option => option.data))
                }
                closeMenuOnSelect={false}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={updateUserMutation.isLoading}
          >
            Update Roles
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
