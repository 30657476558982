import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  useColorModeValue,
  useDisclosure,
  Text,
  VStack,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Tag,
  FormControl,
  Textarea,
  useToast,
  Input,
} from '@chakra-ui/react';
import { LoadingContainer } from '../../../../components/LoadingContainer';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ContentPageTitle, Label } from '../../../../components/Layout';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../../api/API';
import { EmptyStateDisplay } from '../../../../components/EmptyStateDisplay';
import UsersContext from '../../../../contexts/UsersContext';
import { useContext, useEffect, useState } from 'react';

import { TrashIcon, PencilIcon, PlayIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import {
  AddOrEditScheduleModal,
  AddOrEditScheduleModalOnSaveProps,
} from '../AddOrEditScheduleModal';
import {
  TAttestation,
  TAttestationSchedule,
} from '../../../../models/attestation';
import {
  ConfirmModal,
  DeleteConfirmModal,
} from '../../../../components/DashboardPage/DeleteConfirmModal';
import useDebounce from '../../../../hooks/useDebounce';
import useModelSchema from '../../../../hooks/useModelSchema';
import ListBuilder from '../../../../components/ListBuilder';
import _ from 'lodash';
import EditableField from '../../../../components/EditableField';
import { CKEditorWrapper } from '../../../../components/TextContentEditor/CKEditorWrapper';
import { displayFormatedDateFromDateString } from '../../../../utils';

type Props = {};

const defaultQuestionnaireTemplate = `
  <ol><li><h2>Category</h2><ol><li><h5>Question 1</h5><p>[Instructions]</p><ul class="todo-list"><li><label class="todo-list__label"><input type="checkbox" disabled="disabled"><span class="todo-list__label__description">Yes</span></label></li><li><label class="todo-list__label"><input type="checkbox" disabled="disabled"><span class="todo-list__label__description">No</span></label></li></ul><p><strong>Comments:&nbsp;</strong></p><p>&nbsp;</p></li><li><h5>Question 2</h5><p>[Instructions]</p><ul class="todo-list"><li><label class="todo-list__label"><input type="checkbox" disabled="disabled"><span class="todo-list__label__description">Yes</span></label></li><li><label class="todo-list__label"><input type="checkbox" disabled="disabled"><span class="todo-list__label__description">No</span></label></li></ul><p><strong>Comments:&nbsp;</strong></p></li></ol></li></ol>
`;

export default function EditAttestation({}: Props) {
  const addOrEditScheduleModal = useDisclosure();
  const deleteScheduleModal = useDisclosure();
  const executeScheduleModal = useDisclosure();
  const { currentUser, userHasPermission } = useContext(UsersContext);
  const toast = useToast();
  const { cuid } = useParams();

  // Move all color mode values to the top level
  const borderColor = useColorModeValue('neutral.200', 'neutral.800');
  const emptyStateBg = useColorModeValue('neutral.50', 'neutral.900');
  const scheduleBg = useColorModeValue('white', 'transparent');
  const scheduleHoverBg = useColorModeValue(
    'brandSecondary.25',
    'brandSecondary.950',
  );
  const scheduleHoverBorderColor = useColorModeValue(
    'brandSecondary.100',
    'brandSecondary.800',
  );
  const scheduleHoverColor = useColorModeValue('inherit', 'brandSecondary.25');
  const questionnaireWrapperBg = useColorModeValue('white', 'neutral.1000');
  const questionnaireWrapperBorderColor = useColorModeValue(
    'neutral.200',
    'neutral.800',
  );

  const {
    isLoading: loadingSchema,
    data: schemaData,
    propertyItems,
  } = useModelSchema();

  const [scheduleToEdit, setScheduleToEdit] = useState<TAttestationSchedule>();
  const [description, setDescription] = useState<string | undefined>();
  const [customFields, setCustomFields] = useState<
    TAttestation['custom_fields_json'] | undefined
  >();
  const [questionnaireTemplate, setQuestionnaireTemplate] = useState<string>(
    defaultQuestionnaireTemplate,
  );
  const [name, setName] = useState<string | undefined>();

  const debouncedDescription = useDebounce(description, 500);
  const debouncedCustomFields = useDebounce(customFields, 500);

  const attestationQuery = useQuery(
    ['attestation', cuid],
    async () => {
      const fetchedAttestation = await API.GetAttestationByCUID(cuid!);
      setName(fetchedAttestation?.name || '');
      setDescription(fetchedAttestation?.description || '');
      setCustomFields(fetchedAttestation?.custom_fields_json || []);
      if (fetchedAttestation?.questionaire_template) {
        setQuestionnaireTemplate(fetchedAttestation.questionaire_template);
      } else {
        setQuestionnaireTemplate(defaultQuestionnaireTemplate);
      }
      return fetchedAttestation;
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  );

  const attestation = attestationQuery.data;

  const hasSchedules = attestationQuery.data?.schedules.length || 0 > 0;
  const canUpdateAttestations = userHasPermission(
    ['update_attestation'],
    'all',
  );

  useEffect(() => {
    if (
      attestation &&
      debouncedDescription !== undefined &&
      debouncedDescription !== attestation.description
    ) {
      (async () => {
        try {
          await API.UpdateAttestation(attestation!.cuid, {
            description: debouncedDescription,
          });
          toast({
            title: 'Description updated',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } catch (e: any) {
          toast({
            title: 'Error updating description',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })();
    }
  }, [attestation, debouncedDescription]);

  useEffect(() => {
    if (
      attestation &&
      debouncedCustomFields !== undefined &&
      !_.isEqual(debouncedCustomFields, attestation.custom_fields_json)
    ) {
      (async () => {
        try {
          await API.UpdateAttestation(attestation!.cuid, {
            custom_fields_json: debouncedCustomFields,
          });
          toast({
            title: 'Relevant Attestation Fields updated',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        } catch (e: any) {
          toast({
            title: 'Error updating Relevant Attestation Fields',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      })();
    }
  }, [attestation, debouncedCustomFields]);

  const isAdmin = currentUser?.roles.some(role => role.role.is_admin);

  const customFieldsLeftList =
    propertyItems
      ?.filter(
        item =>
          !customFields?.find(customField => customField.fieldId === item.key),
      )
      .map(item => ({ id: item.key, label: item.title })) || [];

  const customFieldsRightList =
    customFields?.reduce((acc, customField) => {
      const item = propertyItems?.find(
        item => item.key === customField.fieldId,
      );
      if (item) {
        acc.push({ id: item.key, label: item.title });
      }
      return acc;
    }, [] as { id: string; label: string }[]) || [];

  return (
    <>
      {canUpdateAttestations && (
        <DeleteConfirmModal
          title={`Attestation Period`}
          text="Are you sure you want to delete? This cannot be undone."
          isOpen={deleteScheduleModal.isOpen}
          onClose={deleteScheduleModal.onClose}
          onConfirm={async () => {
            await API.DeleteAttestationSchedule(
              attestation!.cuid,
              scheduleToEdit!.cuid,
            );
            await attestationQuery.refetch();
            deleteScheduleModal.onClose();
          }}
        />
      )}
      {canUpdateAttestations && (
        <AddOrEditScheduleModal
          existingSchedule={scheduleToEdit}
          isOpen={addOrEditScheduleModal.isOpen}
          onClose={addOrEditScheduleModal.onClose}
          onSave={async (saveObj: AddOrEditScheduleModalOnSaveProps) => {
            try {
              if (saveObj.existingScheduleCUID) {
                await API.UpdateAttestationSchedule(
                  attestation!.cuid,
                  saveObj.existingScheduleCUID,
                  {
                    name: saveObj.name,
                    start_date: saveObj.startDate,
                    end_date: saveObj.endDate,
                  },
                );

                await attestationQuery.refetch();

                return {
                  success: true,
                };
              } else {
                await API.CreateAttestationSchedule(attestation!.cuid, {
                  name: saveObj.name,
                  start_date: saveObj.startDate,
                  end_date: saveObj.endDate,
                });

                await attestationQuery.refetch();

                return {
                  success: true,
                };
              }
            } catch (e: any) {
              let message = e.message;
              if (e.response?.data?.error && e.response?.data?.error.message) {
                message = e.response.data.error.message;
              }
              return {
                success: false,
                error: message,
              };
            }
          }}
        />
      )}
      {canUpdateAttestations && (
        <ConfirmModal
          title={`Execute Attestation Schedule`}
          text="Are you sure you want to execute this attestation schedule? This will set the start date to today's date."
          isOpen={executeScheduleModal.isOpen}
          onClose={executeScheduleModal.onClose}
          onConfirm={async () => {
            try {
              await API.ExecuteAttestationSchedule(
                attestation!.cuid,
                scheduleToEdit!.cuid,
              );

              // refetch the attestation query
              await attestationQuery.refetch();

              toast({
                title: 'Attestation schedule executed successfully',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            } catch (e) {
              toast({
                title: 'Error executing attestation schedule',
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }
            executeScheduleModal.onClose();
          }}
        />
      )}
      <VStack
        alignItems="start"
        spacing={0}
        paddingTop={12}
        mt={1}
        paddingBottom={16}
        px={14}
        gap={8}
        w="full"
        overflow="auto"
        className="no-scrollbar"
        maxWidth={'7xl'}
        mx={'auto'}
      >
        <LoadingContainer isLoading={attestationQuery.isLoading}>
          <Box w="full">
            <Breadcrumbs />
            <HStack w="full" justify="space-between">
              <EditableField
                readOnly={!canUpdateAttestations}
                value={name}
                onCancel={() => {
                  setName(attestation?.name);
                }}
                onSave={async onFinished => {
                  try {
                    if (!name)
                      throw new Error('Attestation name cannot be empty');

                    await API.UpdateAttestation(attestation!.cuid, {
                      name: name,
                    });
                    await attestationQuery.refetch();

                    onFinished(true);
                    toast({
                      title: 'Attestation name updated',
                      status: 'success',
                      duration: 2000,
                      isClosable: true,
                    });
                  } catch (e) {
                    toast({
                      title: 'Error updating attestation name',
                      status: 'error',
                      duration: 2000,
                      isClosable: true,
                    });
                    onFinished(false);
                  }
                }}
                renderField={(
                  mode: 'edit' | 'read-only',
                  isSaving: boolean,
                ) => {
                  if (mode === 'edit') {
                    return (
                      <Input
                        value={name}
                        readOnly={isSaving}
                        onChange={e => setName(e.target.value)}
                      />
                    );
                  } else {
                    return (
                      <ContentPageTitle data-testid="MasterStatusBarText">
                        {attestation?.name}
                      </ContentPageTitle>
                    );
                  }
                }}
              ></EditableField>
            </HStack>
          </Box>
          <VStack w={'full'} gap={10}>
            <VStack w={'full'} align="flex-start" gap={4}>
              <FormControl>
                <Label mb={2}>Description</Label>
                <Textarea
                  readOnly={!canUpdateAttestations}
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                />
              </FormControl>
            </VStack>
            <VStack w={'full'} align="flex-start" gap={2}>
              <HStack w={'full'} justify="space-between">
                <Heading as={'h3'}>Scheduling</Heading>
                {hasSchedules && canUpdateAttestations && (
                  <Button
                    leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                    variant={'ghost'}
                    onClick={() => {
                      setScheduleToEdit(undefined);
                      addOrEditScheduleModal.onOpen();
                    }}
                  >
                    Add Attestation Period
                  </Button>
                )}
              </HStack>
              <Text>
                Define the attestation period and schedule advance user
                reminders.
              </Text>
              {!hasSchedules && (
                <Flex
                  direction="column"
                  p={4}
                  gap={4}
                  alignSelf="stretch"
                  borderRadius="lg"
                  borderWidth={1}
                  borderColor={borderColor}
                  backgroundColor={emptyStateBg}
                  data-testid="to-be-assigned-container"
                >
                  <EmptyStateDisplay>
                    <Heading as={'h5'}>No dates scheduled yet.</Heading>
                    {canUpdateAttestations && (
                      <Button
                        leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                        variant={'ghost'}
                        onClick={() => {
                          setScheduleToEdit(undefined);
                          addOrEditScheduleModal.onOpen();
                        }}
                      >
                        Add Attestation Period
                      </Button>
                    )}
                  </EmptyStateDisplay>
                </Flex>
              )}
              {hasSchedules &&
                attestation?.schedules.map(schedule => (
                  <Flex
                    key={schedule.cuid}
                    width={'full'}
                    p={4}
                    borderWidth={1}
                    borderColor={'var(--chakra-colors-chakra-border-color)'}
                    rounded={'md'}
                    bg={scheduleBg}
                    _hover={{
                      bg: scheduleHoverBg,
                      borderColor: scheduleHoverBorderColor,
                      color: scheduleHoverColor,
                    }}
                    transition="all 0.3s ease-in-out"
                    role="group"
                    onDoubleClick={() => {
                      if (canUpdateAttestations) {
                        setScheduleToEdit(schedule);
                        addOrEditScheduleModal.onOpen();
                      }
                    }}
                  >
                    <HStack w="full" justifyContent={'space-between'}>
                      <VStack align="flex-start">
                        <Heading as={'h5'}>{schedule.name}</Heading>
                      </VStack>
                      <HStack>
                        <Tag>
                          {displayFormatedDateFromDateString(
                            schedule.start_date,
                          )}{' '}
                          -{' '}
                          {displayFormatedDateFromDateString(schedule.end_date)}
                        </Tag>
                        {canUpdateAttestations && (
                          <Menu>
                            <MenuButton
                              onMouseDown={e => e.stopPropagation()}
                              onMouseUp={e => e.stopPropagation()}
                              as={IconButton}
                              aria-label="Options"
                              icon={
                                <Icon as={EllipsisVerticalIcon} boxSize={6} />
                              }
                              variant="ghost"
                              visibility={'hidden'}
                              _groupHover={{ visibility: 'visible' }}
                              size="sm"
                            />
                            <MenuList
                              onMouseDown={e => e.stopPropagation()}
                              onMouseUp={e => e.stopPropagation()}
                              minW={0}
                              w={'200px'}
                            >
                              <MenuItem
                                icon={<Icon as={PencilIcon} boxSize={4} />}
                                onClick={() => {
                                  setScheduleToEdit(schedule);
                                  addOrEditScheduleModal.onOpen();
                                }}
                              >
                                Edit
                              </MenuItem>
                              {!schedule.executed_at && isAdmin && (
                                <MenuItem
                                  icon={<Icon as={PlayIcon} boxSize={4} />}
                                  onClick={() => {
                                    setScheduleToEdit(schedule);
                                    executeScheduleModal.onOpen();
                                  }}
                                >
                                  Execute Schedule
                                </MenuItem>
                              )}
                              <MenuItem
                                color="red.500"
                                icon={<Icon as={TrashIcon} boxSize={4} />}
                                onClick={() => {
                                  setScheduleToEdit(schedule);
                                  deleteScheduleModal.onOpen();
                                }}
                              >
                                Remove
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        )}
                      </HStack>
                    </HStack>
                  </Flex>
                ))}
            </VStack>
            <VStack w={'full'} align="flex-start" gap={4}>
              <Heading as={'h3'}>Relevant Attestation Fields</Heading>
              <Text>
                Select the Model Inventory Fields needed for your attestation
                process by dragging them from the left column to the right. You
                can also reorder fields by dragging them vertically.
              </Text>
              {propertyItems &&
                propertyItems.length > 0 &&
                customFields !== undefined && (
                  <ListBuilder
                    readOnly={!canUpdateAttestations}
                    initialLeftList={customFieldsLeftList}
                    initialRightList={customFieldsRightList}
                    leftListLabel="Model Inventory Fields"
                    rightListLabel="Relevant Attestation Fields"
                    height={500}
                    onRightColumnChanged={cols => {
                      const newCustomFields = cols.map(col => ({
                        fieldId: col.id,
                      }));
                      if (!_.isEqual(newCustomFields, customFields)) {
                        setCustomFields(newCustomFields);
                      }
                    }}
                  />
                )}
            </VStack>
            <VStack w={'full'} align="flex-start" gap={4}>
              <Heading as={'h3'}>Questionnaire Template</Heading>
              <Text>
                This template must be completed and submitted as part of the
                attestation process.
              </Text>
              <EditableField
                readOnly={!canUpdateAttestations}
                value={questionnaireTemplate}
                onSave={async onFinished => {
                  try {
                    await API.UpdateAttestation(attestation!.cuid, {
                      questionaire_template: questionnaireTemplate,
                    });
                    onFinished(true);
                    toast({
                      title: 'Questionaire template updated',
                      status: 'success',
                      duration: 2000,
                      isClosable: true,
                    });
                  } catch (e) {
                    toast({
                      title: 'Error updating questionaire template',
                      status: 'error',
                      duration: 2000,
                      isClosable: true,
                    });
                    onFinished(false);
                  }
                }}
                onCancel={() => {
                  setQuestionnaireTemplate(
                    attestation!.questionaire_template ||
                      defaultQuestionnaireTemplate,
                  );
                }}
                renderField={(
                  mode: 'edit' | 'read-only',
                  isSaving: boolean,
                ) => {
                  if (mode === 'edit') {
                    return (
                      <CKEditorWrapper
                        text={questionnaireTemplate}
                        onChange={text => setQuestionnaireTemplate(text)}
                        readOnly={isSaving}
                        enabledFeatures={{
                          images: false,
                          comments: false,
                          revisions: false,
                          deleteBlock: false,
                          generateWithAI: false,
                        }}
                        hideTooltip={true}
                        showOutline={true}
                        autoSave={false}
                        withHeight={true}
                        containerStyle={{ width: '100%' }}
                      />
                    );
                  } else {
                    return (
                      <Box
                        w={'full'}
                        bg={questionnaireWrapperBg}
                        borderWidth={1}
                        borderColor={questionnaireWrapperBorderColor}
                        minH={100}
                      >
                        <Box
                          className="ck ck-content ck-content"
                          dangerouslySetInnerHTML={{
                            __html: questionnaireTemplate,
                          }}
                        />
                      </Box>
                    );
                  }
                }}
              />
            </VStack>
          </VStack>
        </LoadingContainer>
      </VStack>
    </>
  );
}
