import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  HStack,
  VStack,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Flex,
  InputLeftElement,
  Badge,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowsUpDownIcon,
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import { generateStrongPassword } from '../../utils';
import { useState, useMemo } from 'react';
import { useUsers, useOrganizations } from '../../hooks/admin';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingContainer } from '../../components/LoadingContainer';
import { displayFormatedDateAndTime } from '../../utils';
import AdminAPI from '../../api/AdminAPI';
import { Role, Organization, PostUserRequest } from '../../api/AdminAPI';
import { Link } from 'react-router-dom';
import { useFlags, AuthConfig } from '../../hooks/useFlags';

type SortField = 'email' | 'created_at' | 'updated_at';
type SortOrder = 'asc' | 'desc';

function Users() {
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal controls
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { data: users, isLoading, refetch } = useUsers();
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [userPassword, setUserPassword] = useState(generateStrongPassword());
  const [showPassword, setShowPassword] = useState(false);

  const { data: organizations, isLoading: isOrganizationsLoading } =
    useOrganizations();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [sortField, setSortField] = useState<SortField>('created_at');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  // Add new state for success modal
  const successModalDisclosure = useDisclosure();
  const [createdUser, setCreatedUser] = useState<any>(null);
  const [showCreatedPassword, setShowCreatedPassword] = useState(false);

  const {
    authConfig = {
      auth_create_user_enabled: false,
    },
  } = useFlags() as { authConfig: AuthConfig };

  const filteredUsers = useMemo(() => {
    if (!users) return [];

    return users.filter(user => {
      const searchLower = searchQuery.toLowerCase();
      return (
        user.email.toLowerCase().includes(searchLower) ||
        user.cuid.toLowerCase().includes(searchLower) ||
        user.name.toLowerCase().includes(searchLower)
      );
    });
  }, [users, searchQuery]);

  // Update sorting logic to use filtered users
  const sortedUsers = useMemo(() => {
    if (!filteredUsers) return [];

    return [...filteredUsers].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (sortOrder === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });
  }, [filteredUsers, sortField, sortOrder]);

  // Update pagination calculations
  const totalItems = filteredUsers?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUsers = sortedUsers?.slice(startIndex, endIndex);

  const createUserMutation = useMutation({
    mutationFn: async (newUser: PostUserRequest) => {
      return AdminAPI.PostUser(newUser);
    },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'users'] });
      setCreatedUser({
        ...response,
        password: variables.password,
        organizationName: selectedOrganization?.name,
        roles: selectedRoles,
      });
      resetModal();
      successModalDisclosure.onOpen();
    },
    onError: (error: any) => {
      console.log('Error object:', error);
      console.log('Error response:', error.response?.data);
      toast({
        variant: 'subtle',
        title: 'Error creating user',
        description: error.response?.data?.message || 'Failed to create user',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const resetModal = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setUserPassword(generateStrongPassword());
    onClose();
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field) {
      return <Icon as={ArrowsUpDownIcon} ml={2} w={4} h={4} />;
    }
    if (sortOrder === 'asc') {
      return <Icon as={ChevronUpIcon} ml={2} w={4} h={4} />;
    }
    return <Icon as={ChevronDownIcon} ml={2} w={4} h={4} />;
  };

  const handleSubmit = () => {
    const payload: PostUserRequest = {
      first_name: firstName,
      last_name: lastName,
      ...(authConfig.auth_create_user_enabled && { password: userPassword }),
      email,
      organization: {
        organization_cuid: selectedOrganization?.cuid || '',
        roles: selectedRoles.map(role => role.cuid),
      },
    };
    createUserMutation.mutate(payload);
  };

  return (
    <Box>
      <Flex justify="space-between" align="center" mb={6}>
        <Heading>Users</Heading>
        <Button
          leftIcon={<Icon as={PlusIcon} w={4} h={4} />}
          onClick={onOpen}
          variant="primary"
        >
          Create User
        </Button>
      </Flex>

      <Text mb={4} color="gray.600">
        Total Users: {users?.length || 0}
      </Text>

      <InputGroup mb={4} maxW="md">
        <InputLeftElement pointerEvents="none">
          <Icon as={MagnifyingGlassIcon} color="gray.400" w={4} h={4} />
        </InputLeftElement>
        <Input
          placeholder="Search by name, email, or CUID..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      {/* Create User Modal */}
      <Modal isOpen={isOpen} onClose={resetModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6} align="stretch">
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Enter user's email"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>First Name</FormLabel>
                <Input
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  placeholder="Enter user's first name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Last Name</FormLabel>
                <Input
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  placeholder="Enter user's last name"
                />
              </FormControl>

              {authConfig.auth_create_user_enabled && (
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={userPassword}
                      onChange={e => setUserPassword(e.target.value)}
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Icon
                          as={showPassword ? EyeSlashIcon : EyeIcon}
                          w={4}
                          h={4}
                        />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    *User password only takes effect when ValidMind manages the
                    identity provider user provisioning
                  </Text>
                  <Button
                    size="sm"
                    variant="link"
                    mt={2}
                    onClick={() => setUserPassword(generateStrongPassword())}
                  >
                    Generate new password
                  </Button>
                </FormControl>
              )}

              <FormControl isRequired>
                <FormLabel>Organization</FormLabel>
                <Select
                  isLoading={isOrganizationsLoading}
                  options={organizations?.map(org => ({
                    value: org.cuid,
                    label: org.name,
                    data: org,
                  }))}
                  placeholder="Select an organization"
                  onChange={selectedOption => {
                    setSelectedOrganization(selectedOption?.data || null);
                    setSelectedRoles([]);
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Roles</FormLabel>
                <Select
                  isMulti
                  isDisabled={!selectedOrganization}
                  options={
                    selectedOrganization?.roles
                      .filter(role => role.scope !== 'Model')
                      .map(role => ({
                        value: role.cuid,
                        label: role.name,
                        data: role,
                      })) || []
                  }
                  placeholder="Select roles"
                  value={selectedRoles.map(role => ({
                    value: role.cuid,
                    label: role.name,
                    data: role,
                  }))}
                  onChange={selectedOptions =>
                    setSelectedRoles(selectedOptions.map(option => option.data))
                  }
                  closeMenuOnSelect={false}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={resetModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              isLoading={createUserMutation.isLoading}
              isDisabled={
                !email ||
                !firstName ||
                !lastName ||
                !selectedOrganization ||
                selectedRoles.length === 0 ||
                createUserMutation.isLoading
              }
            >
              Create User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Success Modal */}
      <Modal
        isOpen={successModalDisclosure.isOpen}
        onClose={successModalDisclosure.onClose}
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Created Successfully</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontWeight="bold" mb={1}>
                  Name
                </Text>
                <Text>{createdUser?.name}</Text>
              </Box>

              <Box>
                <Flex justify="space-between" align="center" mb={1}>
                  <Text fontWeight="bold">Email</Text>
                  <Button
                    size="xs"
                    variant="ghost"
                    onClick={() => {
                      navigator.clipboard.writeText(createdUser?.email);
                      toast({
                        variant: 'subtle',
                        title: 'Email copied',
                        status: 'success',
                        duration: 2000,
                      });
                    }}
                  >
                    Copy
                  </Button>
                </Flex>
                <Text>{createdUser?.email}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold" mb={1}>
                  Organization
                </Text>
                <Text>{createdUser?.organizationName}</Text>
              </Box>

              <Box>
                <Text fontWeight="bold" mb={1}>
                  Roles
                </Text>
                <HStack spacing={2} wrap="wrap">
                  {createdUser?.roles.map((role: Role) => (
                    <Badge key={role.cuid} colorScheme="blue">
                      {role.name}
                    </Badge>
                  ))}
                </HStack>
              </Box>

              {authConfig.auth_create_user_enabled && (
                <Box>
                  <Flex justify="space-between" align="center" mb={1}>
                    <Text fontWeight="bold">Password</Text>
                    <HStack>
                      <Button
                        size="xs"
                        variant="ghost"
                        onClick={() =>
                          setShowCreatedPassword(!showCreatedPassword)
                        }
                        leftIcon={
                          <Icon
                            as={showCreatedPassword ? EyeSlashIcon : EyeIcon}
                            w={4}
                            h={4}
                          />
                        }
                      >
                        {showCreatedPassword ? 'Hide' : 'Show'}
                      </Button>
                      <Button
                        size="xs"
                        variant="ghost"
                        onClick={() => {
                          navigator.clipboard.writeText(createdUser?.password);
                          toast({
                            variant: 'subtle',
                            title: 'Password copied',
                            status: 'success',
                            duration: 2000,
                          });
                        }}
                      >
                        Copy
                      </Button>
                    </HStack>
                  </Flex>
                  <Text>
                    {showCreatedPassword
                      ? createdUser?.password
                      : '••••••••••••••••'}
                  </Text>
                </Box>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={successModalDisclosure.onClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <LoadingContainer isLoading={isLoading}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th cursor="pointer" onClick={() => handleSort('email')}>
                Email {getSortIcon('email')}
              </Th>
              <Th>Name</Th>
              <Th>CUID</Th>
              <Th>Organizations</Th>
              <Th cursor="pointer" onClick={() => handleSort('created_at')}>
                Created At {getSortIcon('created_at')}
              </Th>
              <Th cursor="pointer" onClick={() => handleSort('updated_at')}>
                Updated At {getSortIcon('updated_at')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentUsers?.map(user => (
              <Tr key={user.cuid}>
                <Td>
                  <Link to={`/admin/users/${user.cuid}`}>
                    <Text
                      color="brand.500"
                      _hover={{ textDecoration: 'underline' }}
                    >
                      {user.email}
                    </Text>
                  </Link>
                </Td>
                <Td>{user.name}</Td>
                <Td>{user.cuid}</Td>
                <Td>
                  {user.organizations?.map((org, index) => (
                    <Box key={org.cuid} display="inline">
                      <Link to={`/admin/organizations/${org.cuid}`}>
                        <Text
                          display="inline"
                          color="brand.500"
                          _hover={{ textDecoration: 'underline' }}
                        >
                          {org.name}
                        </Text>
                      </Link>
                      {index < user.organizations.length - 1 ? ', ' : ''}
                    </Box>
                  )) || 'N/A'}
                </Td>
                <Td>{displayFormatedDateAndTime(user.created_at)}</Td>
                <Td>{displayFormatedDateAndTime(user.updated_at)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {totalPages > 1 && (
          <HStack spacing={2} justify="center" mt={6}>
            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage - 1)}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              size="sm"
              onClick={() => setCurrentPage(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        )}
      </LoadingContainer>
    </Box>
  );
}

export default Users;
