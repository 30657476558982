import { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useRadioGroup,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Label } from '../Layout';
import { useMutation } from 'react-query';
import API from '../../api/API';
import { useNavigate } from 'react-router-dom';
import {
  EntityEventType,
  EntityName,
  ExpectedDurationUnit,
  RecurrenceUnit,
  ScheduleType,
  TriggerType,
} from '../../models/workflow';
import RadioCard from '../RadioCard';
import { CheckCircleIcon } from '@chakra-ui/icons';

interface AddWorkflowModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AddWorkflowModal({ isOpen, onClose }: AddWorkflowModalProps) {
  const toast = useToast();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [expectedDurationAmount, setExpectedDurationAmount] =
    useState<number>();
  const [expectedDurationUnit, setExpectedDurationUnit] =
    useState<ExpectedDurationUnit>();
  const [triggerType, setTriggerType] = useState<TriggerType>(
    TriggerType.MANUAL,
  );
  const [entityName, setEntityName] = useState<EntityName>();
  const [entityEventType, setEntityEventType] = useState<EntityEventType>();
  const [scheduleType, setScheduleType] = useState<ScheduleType>();
  const [recurrenceUnit, setRecurrenceUnit] = useState<RecurrenceUnit>();
  const [recurrenceIntervalCount, setRecurrenceIntervalCount] =
    useState<number>();
  const [recurrenceTimeOfDay, setRecurrenceTimeOfDay] = useState<string>('');
  const [recurrenceDayOfWeek, setRecurrenceDayOfWeek] = useState<string>('');
  const [recurrenceDayOfMonth, setRecurrenceDayOfMonth] = useState<number>();
  const [recurrenceCronExpression, setRecurrenceCronExpression] =
    useState<string>('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const postWorkflow = useMutation(
    [],
    async () => {
      return API.PostWorkflow(
        title,
        description,
        'triggerId',
        triggerType,
        entityName,
        entityEventType,
        scheduleType,
        recurrenceUnit,
        recurrenceIntervalCount,
        recurrenceTimeOfDay,
        recurrenceDayOfWeek,
        recurrenceDayOfMonth,
        recurrenceCronExpression,
        expectedDurationAmount,
        expectedDurationUnit,
      );
    },
    {
      onSuccess: data => {
        toast({
          variant: 'subtle',
          title: `${data.title}`,
          description: `Workflow created successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate(`/settings/workflows/${data.cuid}/latest`);
      },
      onError: (error: Error) => {
        setError(API.getAPIErrorMessage(error));
      },
    },
  );

  const { getRadioProps } = useRadioGroup({
    name: 'triggerType',
    defaultValue: TriggerType.MANUAL,
    onChange: (value: TriggerType) => {
      setTriggerType(value);
      if (value === TriggerType.APPLICATION_EVENT) {
        setEntityName(EntityName.INVENTORY_MODEL);
        setEntityEventType(EntityEventType.CREATED);
      } else {
        setEntityName(undefined);
        setEntityEventType(undefined);
      }
    },
  });

  const onClickSave = async () => {
    setError('');
    postWorkflow.mutate();
  };

  const enableSave = title.length > 0 && description.length > 0;

  return (
    <Modal isCentered isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Workflow</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={4}>
            {error && (
              <Box>
                <Alert status="error" variant="subtle">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>
                      Something went wrong configuring your new workflow
                    </AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Box>
                </Alert>
              </Box>
            )}
            <FormControl isRequired>
              <Label mb={2}>Title</Label>
              <Input
                type="text"
                value={title}
                onChange={event => setTitle(event.target.value)}
              />
            </FormControl>

            <FormControl isRequired>
              <Label mb={2}>Description</Label>
              <Textarea
                value={description}
                onChange={event => setDescription(event.target.value)}
                rows={4}
              ></Textarea>
            </FormControl>
            <FormControl isRequired>
              <Label mb={2}>Workflow start</Label>
              <SimpleGrid columns={2} spacing="2">
                {[TriggerType.MANUAL, TriggerType.APPLICATION_EVENT].map(
                  value => {
                    const radio = getRadioProps({ value });
                    return (
                      <RadioCard
                        borderWidth={1}
                        px={4}
                        py={2}
                        color={
                          triggerType === value
                            ? 'brand.800'
                            : useColorModeValue('neutral.800', 'neutral.200')
                        }
                        _hover={{
                          bg:
                            triggerType === value
                              ? 'brand.25'
                              : 'brandSecondary.25',
                          borderColor:
                            triggerType === value
                              ? 'brand.base'
                              : 'brandSecondary.600',
                        }}
                        rounded={'md'}
                        transition={'all 0.3s ease-out'}
                        {...radio}
                      >
                        <HStack alignItems={'flex-start'}>
                          <VStack gap={0} alignItems={'flex-start'}>
                            <Text fontWeight={'bold'}>
                              {value == TriggerType.APPLICATION_EVENT
                                ? `On Model Registration`
                                : `Manually`}
                            </Text>
                            <Text>
                              {value == TriggerType.APPLICATION_EVENT
                                ? 'The workflow automatically starts when the model is registered.'
                                : 'The user initiates the workflow from the model.'}
                            </Text>
                          </VStack>
                          <Spacer />
                          <Icon
                            as={CheckCircleIcon}
                            color={
                              triggerType === value
                                ? 'brand.base'
                                : useColorModeValue(
                                    'neutral.200',
                                    'neutral.800',
                                  )
                            }
                            boxSize={5}
                            transition={'all 0.3s ease-in-out'}
                          />
                        </HStack>
                      </RadioCard>
                    );
                  },
                )}
              </SimpleGrid>
            </FormControl>
            <FormControl isRequired>
              <Label mb={2}>Workflow Expected Duration</Label>
              <SimpleGrid columns={2} spacing="2">
                <Input
                  type="number"
                  value={expectedDurationAmount}
                  onChange={event =>
                    setExpectedDurationAmount(parseInt(event.target.value))
                  }
                  placeholder={'1'}
                />
                <Select
                  value={expectedDurationUnit}
                  onChange={event =>
                    setExpectedDurationUnit(
                      event.target.value as ExpectedDurationUnit,
                    )
                  }
                  placeholder={'Select'}
                >
                  <option value={ExpectedDurationUnit.DAYS}>Days</option>
                  <option value={ExpectedDurationUnit.WEEKS}>Weeks</option>
                  <option value={ExpectedDurationUnit.MONTHS}>Months</option>
                  <option value={ExpectedDurationUnit.YEARS}>Years</option>
                </Select>
              </SimpleGrid>
              <FormHelperText>
                Defines the SLA due date based on the workflow start date.
              </FormHelperText>
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Flex width="100%">
            <Button onClick={onClose} variant={'ghost'}>
              Cancel
            </Button>
            <Spacer />
            <Button
              isDisabled={!enableSave}
              onClick={onClickSave}
              variant={'primary'}
              isLoading={postWorkflow.isLoading}
              loadingText="Adding Workflow..."
            >
              Add Workflow
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AddWorkflowModal;
