import {
  Heading,
  HStack,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import {
  Execution,
  ExecutionStatus,
  Workflow,
} from '../../../../models/workflow';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { useDelayDays } from '../../hooks/useDelayDays';
import { getApprovals } from '../../../../pages/Settings/Workflows/components/utils';
import ExecutionProgress from '../../../ExecutionProgress';
import ExecutionStateTag from '../../../ExecutionStateTag';
import { displayFormatedDateAndTime } from '../../../../utils';
import WorkflowActionButtons from '../WorkflowActionButtons';
import WorkflowOptionsMenu from '../WorkflowOptionsMenu';
import WorkflowApprovalActions from '../../../WorkflowApprovalActions';

interface ExecutionItemProps {
  target: any;
  workflow: Workflow;
  execution?: Execution;
}

function ExecutionItem({ target, workflow, execution }: ExecutionItemProps) {
  const { navigate, params } = useNavigation();
  const delayDays = useDelayDays(execution);
  const approvals = getApprovals(workflow?.version?.source?.nodes || []);

  const handleClick = async () => {
    if (execution) {
      navigate(WorkflowManagerNavigationState.DETAIL, {
        executionCuid: execution.cuid,
        workflowCuid: workflow.cuid,
        isLoading: true,
        listingTab: params.listingTab,
      });
    } else {
      navigate(WorkflowManagerNavigationState.DETAIL, {
        workflowCuid: workflow.cuid,
        isLoading: true,
        listingTab: params.listingTab,
      });
    }
  };

  return (
    <Stack
      id={
        execution ? `execution-${execution.cuid}` : `workflow-${workflow.cuid}`
      }
      p={4}
      gap={2}
      borderWidth={1}
      borderRadius="md"
      borderColor="var(--chakra-colors-chakra-border-color)"
      borderStyle="solid"
      cursor="pointer"
      onClick={handleClick}
      pointerEvents="auto"
      overflow="scroll"
      alignItems="flex-start"
      _hover={{
        bg: useColorModeValue('brandSecondary.25', 'brandSecondary.900'),
        color: useColorModeValue('brandSecondary.800', 'brandSecondary.25'),
        borderColor: 'brandSecondary.600',
      }}
      color={useColorModeValue('brandSecondary.600', 'brandSecondary.100')}
      transition="background, border 0.3s ease-in-out"
      data-testid="workflow-execution-item"
    >
      <HStack w="full" alignItems="flex-start">
        {/* Name and Progress */}
        <Stack gap={2} w="30%">
          <Heading as="h5">
            {execution
              ? `${execution.execution_id} - ${workflow.title}`
              : workflow.title}
          </Heading>
          {execution && execution.progress && (
            <ExecutionProgress progress={execution.progress} />
          )}
        </Stack>

        {/* Status and Dates */}
        <VStack
          gap={2}
          w="50%"
          alignItems="flex-start"
          fontSize="sm"
          color="gray.500"
        >
          {workflow.expected_duration_amount && (
            <Text>
              Expected duration: {workflow.expected_duration_amount}{' '}
              {workflow.expected_duration_unit}.
            </Text>
          )}
          {execution && (
            <>
              {execution.state && <ExecutionStateTag state={execution.state} />}
              <Text>
                Created on: {displayFormatedDateAndTime(execution.created_at)}
              </Text>

              {execution.started_at && (
                <Text>
                  Started on {displayFormatedDateAndTime(execution.started_at)}
                </Text>
              )}

              {execution.status === ExecutionStatus.SCHEDULED && (
                <Text>
                  Scheduled to{' '}
                  {displayFormatedDateAndTime(execution.scheduled_at)}
                </Text>
              )}

              {execution.status === ExecutionStatus.WAITING && (
                <Text>
                  Waiting until{' '}
                  {displayFormatedDateAndTime(execution.resume_wait_at)}
                </Text>
              )}

              {execution.status === ExecutionStatus.FINISHED && (
                <Text>
                  Finished at{' '}
                  {displayFormatedDateAndTime(execution.finished_at)}
                </Text>
              )}

              {execution.status === ExecutionStatus.ABORTED && (
                <Text>
                  Aborted on {displayFormatedDateAndTime(execution.aborted_at)}
                </Text>
              )}

              {execution.expected_end_at && (
                <HStack>
                  <Text>
                    Expected end date:{' '}
                    {displayFormatedDateAndTime(execution.expected_end_at)}
                  </Text>
                  {delayDays && delayDays > 0 && (
                    <Tag
                      rounded="md"
                      colorScheme="red"
                      variant="outline"
                      py={0.5}
                    >
                      <TagLeftIcon boxSize="6" as={ExclamationCircleIcon} />
                      {`Late by ${delayDays.toFixed(1)} day${
                        delayDays > 1 ? 's' : ''
                      }`}
                    </Tag>
                  )}
                </HStack>
              )}
            </>
          )}
          {approvals.length > 0 && (
            <Text>
              This workflow contains {approvals.length} approval{' '}
              {approvals.length === 1 ? 'step' : 'steps'}.
            </Text>
          )}
        </VStack>

        {/* Actions */}
        <HStack w="20%" justifyContent="flex-end">
          <WorkflowActionButtons
            target={target}
            workflow={workflow}
            execution={execution}
          />
          <WorkflowOptionsMenu workflow={workflow} execution={execution} />
        </HStack>
      </HStack>

      {execution && (
        <WorkflowApprovalActions
          targetCuid={target.cuid}
          executionCuid={execution.cuid}
        />
      )}
    </Stack>
  );
}

export default ExecutionItem;
