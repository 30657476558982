import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Button,
  Card,
  CardBody,
  CardFooter,
  Icon,
  Box,
  Text,
  Alert,
  HStack,
  AlertIcon,
  VStack,
  AlertDescription,
  Tag,
  TagLeftIcon,
  CardHeader,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { Socket } from 'socket.io-client';
import { createNewSocket } from '../../socket';
import { UsersContext } from '../../contexts';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useFlags } from '../../hooks/useFlags';

import './styles.css';
import { TInventoryModel } from '../../models/inventory_model';
import { useAuth } from 'react-oidc-context';

type DocumentCheckerProps = {
  inventoryModel: TInventoryModel;
  isDocumentComplete: boolean;
};

type DocumentCheckerState = 'init' | 'idle' | 'generating';

const regulationMap: Record<string, string> = {
  'sr-11-7': 'SR 11-7',
  'ss-1-23': 'SS1 /23',
  'e-23': 'E23',
  off: 'Off',
};

const DocumentChecker: React.FC<DocumentCheckerProps> = ({
  inventoryModel,
  isDocumentComplete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [documentText, setDocumentText] = useState('');
  const [socket, setSocket] = useState<Socket>();
  const [generationState, setGenerationState] =
    useState<DocumentCheckerState>('init');
  const { currentUser } = useContext(UsersContext);
  const auth = useAuth();
  const { user } = auth;

  const { validcheckRecommendationsRegulation = 'sr-11-7' } = useFlags();

  const eventName = `validcheck-recommendations`;
  const streamEvent = `validcheck-recommendations-${inventoryModel.cuid}`;

  const localStorageKey = `validcheck-document-${inventoryModel.cuid}`;

  const closeSocket = useCallback(() => {
    if (socket) {
      socket.off(streamEvent);
      if (socket.connected) {
        socket.disconnect();
      }
      setSocket(undefined);
    }
  }, [socket, streamEvent]);

  const handleSocketReponse = (o: any) => {
    if (o.finished) {
      setGenerationState('idle');
      closeSocket();
    } else {
      setGenerationState('generating');
      setDocumentText(text => {
        let newText = text + o.content;
        return newText;
      });
    }
  };

  const executeDocumentCheck = useCallback(() => {
    (async () => {
      try {
        closeSocket();
        setGenerationState('generating');
        setDocumentText('');

        const sock = await createNewSocket();
        setSocket(sock);

        sock.on(streamEvent, handleSocketReponse);
        sock.emit(eventName, {
          accessToken: user?.access_token,
          inventory_model_cuid: inventoryModel.cuid,
          user_cuid: currentUser!.cuid,
        });
      } catch (error) {
        console.log({ error });
      }
    })();
  }, [closeSocket, eventName, streamEvent, handleSocketReponse]);

  // This use effect triggers after document generation is complete
  useEffect(() => {
    if (generationState === 'idle') {
      localStorage.setItem(localStorageKey, documentText);
    }
  }, [generationState, documentText]);

  useEffect(() => {
    if (isOpen) {
      // get last document check from localstorage (TEMP)
      if (isDocumentComplete) {
        const lastDocumentCheck = localStorage.getItem(localStorageKey);
        if (!lastDocumentCheck) {
          executeDocumentCheck();
        } else {
          setDocumentText(lastDocumentCheck);
        }
      }
    } else {
      closeSocket();
      setGenerationState('init');
    }
  }, [isOpen]);

  return (
    <>
      {validcheckRecommendationsRegulation !== 'off' && (
        <Card
          variant="filled"
          className="ai-card-bg"
          size={'sm'}
          rounded={'lg'}
          p={0.5}
        >
          <CardHeader
            bg={useColorModeValue('white', 'black')}
            roundedTop={'md'}
            p={4}
          >
            <Heading as={'h5'}>Document Checker</Heading>
          </CardHeader>
          <CardBody bg={useColorModeValue('white', 'black')} px={4} py={0}>
            View recommendations to enhance your model documentation against
            applicable regulatory guidelines with ValidMind's{' '}
            <strong>Virtual Document Validator </strong>
            <sup className="beta">Beta</sup>
          </CardBody>
          <CardFooter
            bg={useColorModeValue('white', 'black')}
            roundedBottom={'md'}
            p={4}
          >
            <Button
              onClick={onOpen}
              leftIcon={<Icon as={CheckIcon} boxSize={4} />}
              width={'full'}
              variant={'outline'}
              data-testid="gen-ai-check-document-btn"
            >
              Check Document
            </Button>
          </CardFooter>
        </Card>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Check Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <Stack>
              <Text>
                ValidMind's{' '}
                <strong>
                  Virtual Document Validator <sup className="beta">Beta</sup>
                </strong>{' '}
                analyzes your completed documentation by comparing it against
                regulatory standards (
                {
                  regulationMap[
                    validcheckRecommendationsRegulation as keyof typeof regulationMap
                  ]
                }
                ). It identifies gaps and provides actionable recommendations,
                helping you focus your efforts on areas that require attention
                for improved compliance and efficiency.
              </Text>
              {!isDocumentComplete && (
                <Alert status="info" variant={'left-accent'} hidden={false}>
                  <HStack alignItems={'flex-start'} gap={0}>
                    <AlertIcon />
                    <VStack alignItems={'flex-start'} gap={0}>
                      <AlertDescription>
                        Please complete your entire documentation{' '}
                        <Tag size={'md'} colorScheme="green" rounded={'full'}>
                          <TagLeftIcon boxSize={4} as={CheckIcon} />
                          Done
                        </Tag>{' '}
                        to enable regulatory standards validation.
                      </AlertDescription>
                    </VStack>
                  </HStack>
                </Alert>
              )}
              {isDocumentComplete && (
                <>
                  <Box className="ai-card-bg" pl={1}>
                    <Box
                      p={8}
                      bg={'background'}
                      dangerouslySetInnerHTML={{ __html: documentText }}
                    ></Box>
                  </Box>
                  <Stack alignItems={'flex-end'}>
                    <Button
                      isLoading={generationState === 'generating'}
                      isDisabled={
                        generationState === 'generating' || !isDocumentComplete
                      }
                      size={'md'}
                      onClick={executeDocumentCheck}
                      data-testid="gen-ai-check-again-btn"
                    >
                      Check Again
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </ModalBody>

          {/* <ModalFooter>
            <HStack gap={2}>
              <Button onClick={onClose}>Close</Button>
              <Button variant="ghost">Check Document</Button>
            </HStack>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DocumentChecker;
