import {
  Box,
  Flex,
  HStack,
  Icon,
  Stack,
  Tag,
  TagLeftIcon,
  Text,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { CubeIcon } from '@heroicons/react/24/outline';
import { Execution, Workflow } from '../../../../models/workflow';
import { Label } from '../../../Layout';
import ExecutionProgress from '../../../ExecutionProgress';
import { displayFormatedDateAndTime } from '../../../../utils';
import { getApprovals } from '../../../../pages/Settings/Workflows/components/utils';
import { useDelayDays } from '../../hooks/useDelayDays';
import { LoadingContainer } from '../../../LoadingContainer';
import _ from 'lodash';

interface ExecutionDetailsProps {
  workflow: Workflow;
  execution?: Execution;
}

function ExecutionDetails({ workflow, execution }: ExecutionDetailsProps) {
  const delayDays = useDelayDays(execution);
  const approvals = getApprovals(workflow?.version?.source?.nodes || []);

  return (
    <LoadingContainer isLoading={false}>
      <Flex>
        <Box flex={3}>
          <Stack gap={8}>
            {workflow.description !== '' && (
              <Stack>
                <Label>Description</Label>
                <Text>{workflow.description}</Text>
              </Stack>
            )}

            {!execution &&
              workflow.expected_duration_amount &&
              workflow.expected_duration_unit && (
                <Stack>
                  <Label>Expected Duration</Label>
                  <Text>
                    {workflow.expected_duration_amount}{' '}
                    {workflow.expected_duration_unit}
                  </Text>
                </Stack>
              )}

            {execution && (
              <>
                {execution.progress && (
                  <Stack>
                    <Label>Progress</Label>
                    <ExecutionProgress progress={execution.progress} />
                  </Stack>
                )}
                <Stack>
                  <Label>Running on model</Label>
                  <HStack>
                    <Icon as={CubeIcon} boxSize={6} />
                    <Text>{execution.target.name}</Text>
                  </HStack>
                </Stack>

                {approvals.length > 0 && (
                  <Stack>
                    <Label>Approval Process</Label>
                    <HStack>
                      <Text>
                        This workflow contains {approvals.length}{' '}
                        {approvals.length === 1 ? 'approval' : 'approvals'}
                      </Text>
                    </HStack>
                  </Stack>
                )}

                <Stack>
                  <Label>Workflow Status</Label>
                  <Text>{_.capitalize(execution.status)}</Text>
                </Stack>
              </>
            )}
          </Stack>
        </Box>

        <Box flex={1}>
          {execution && (
            <Stack gap={8}>
              <Stack>
                <Label>Created On</Label>
                <Text>{displayFormatedDateAndTime(execution.created_at)}</Text>
              </Stack>
              {workflow.expected_duration_amount &&
                workflow.expected_duration_unit && (
                  <Stack>
                    <Label>Expected Duration</Label>
                    <Text>
                      {workflow.expected_duration_amount}{' '}
                      {workflow.expected_duration_unit}
                    </Text>
                  </Stack>
                )}
              {execution.scheduled_at && (
                <Stack>
                  <Label>Scheduled To</Label>
                  <Text>
                    {displayFormatedDateAndTime(execution.scheduled_at)}
                  </Text>
                </Stack>
              )}
              {execution.started_at && (
                <Stack>
                  <Label>Started On</Label>
                  <Text>
                    {displayFormatedDateAndTime(execution.started_at)}
                  </Text>
                </Stack>
              )}
              {execution.resume_wait_at && (
                <Stack>
                  <Label>Waiting Until</Label>
                  <Text>
                    {displayFormatedDateAndTime(execution.resume_wait_at)}
                  </Text>
                </Stack>
              )}

              {execution.expected_end_at && (
                <Stack>
                  <Label>Expected end date</Label>
                  <Text>
                    {displayFormatedDateAndTime(execution.expected_end_at)}
                  </Text>
                  {delayDays && delayDays > 0 && (
                    <Box>
                      <Tag
                        rounded="md"
                        colorScheme="red"
                        variant="outline"
                        py={0.5}
                      >
                        <TagLeftIcon boxSize="6" as={ExclamationCircleIcon} />
                        {`Late by ${delayDays.toFixed(1)} day${
                          delayDays && delayDays > 1 ? 's' : ''
                        }`}
                      </Tag>
                    </Box>
                  )}
                </Stack>
              )}
            </Stack>
          )}
        </Box>
      </Flex>
    </LoadingContainer>
  );
}

export default ExecutionDetails;
