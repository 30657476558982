import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  HStack,
  Icon,
  Input,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useRadioGroup,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Execution, Workflow } from '../../../../models/workflow';
import { useModal } from '../../contexts/modal/ModalContext';
import {
  useNavigation,
  WorkflowManagerNavigationState,
} from '../../contexts/navigation/NavigationContext';
import { Label } from '../../../Layout';
import RadioCard from '../../../RadioCard';
import API from '../../../../api/API';
import { ActivityFeedWidgetQueryKey } from '../../../ActivityFeedWidget';
import { useWorkflow } from '../../contexts/workflow/WorkflowContext';
import {
  convertUTCToLocal,
  displayFormatedDateAndTime,
} from '../../../../utils';

interface WorkflowStartProps {
  target: any;
  workflow: Workflow;
  execution?: Execution;
  isReschedule?: boolean;
}

export enum WhenToStart {
  NOW = 'now',
  SPECIFIC = 'specific',
}

function WorkflowStart({
  target,
  workflow,
  execution,
  isReschedule = false,
}: WorkflowStartProps) {
  const { setFooterContent } = useModal();
  const { navigate } = useNavigation();
  const { loadWorkflowExecution } = useWorkflow();
  const queryClient = useQueryClient();
  const toast = useToast();

  // Default to SPECIFIC if we're rescheduling and have a scheduled date, otherwise default to NOW
  const defaultWhenToStart = execution?.scheduled_at
    ? WhenToStart.SPECIFIC
    : WhenToStart.NOW;

  const [whenToStart, setWhenToStart] = useState(defaultWhenToStart);
  const [startDate, setStartDate] = useState('');
  const [disabled, setDisabled] = useState(false);

  // Initialize start date if rescheduling
  useEffect(() => {
    if (execution?.scheduled_at) {
      setStartDate(convertUTCToLocal(execution.scheduled_at));
    }
  }, [execution]);

  // Start workflow mutation
  const { mutate: start, isLoading: isLoadingStart } = useMutation(
    async () => {
      // If running now, pass empty string for startDate
      const dateToUse = whenToStart === WhenToStart.NOW ? '' : startDate;
      return API.StartWorkflowExecution(workflow.cuid, target.cuid, dateToUse);
    },
    {
      onSuccess: data => {
        handleSuccess(data);
        const actionType =
          whenToStart === WhenToStart.NOW ? 'started' : 'scheduled';
        toast({
          variant: 'subtle',
          title: `Workflow ${actionType} successfully`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: error => {
        toast({
          variant: 'subtle',
          title: 'Error starting workflow',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  // Reschedule workflow mutation
  const { mutate: reschedule, isLoading: isLoadingReschedule } = useMutation(
    async () => {
      // If rescheduling to run now, pass empty string for startDate
      const dateToUse =
        whenToStart === WhenToStart.NOW ? WhenToStart.NOW : startDate;
      return API.RescheduleWorkflowExecution(
        workflow.cuid,
        execution!.cuid,
        dateToUse,
      );
    },
    {
      onSuccess: data => {
        handleSuccess(data);
        const actionType =
          whenToStart === WhenToStart.NOW
            ? 'rescheduled to run now'
            : 'rescheduled';
        toast({
          variant: 'subtle',
          title: `Workflow ${actionType} successfully`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      },
      onError: error => {
        toast({
          variant: 'subtle',
          title: 'Error rescheduling workflow',
          description: API.getAPIErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  // Handle successful mutation
  async function handleSuccess(updatedExecution: Execution) {
    // First update the workflow context
    await loadWorkflowExecution(workflow.cuid, updatedExecution.cuid);

    // Then invalidate queries for other components
    queryClient.invalidateQueries(['targets', target.cuid, 'executions']);
    queryClient.invalidateQueries([
      'targets',
      target.cuid,
      'workflows',
      'available',
    ]);
    queryClient.invalidateQueries([ActivityFeedWidgetQueryKey]);
    queryClient.invalidateQueries(['approvals', 'voters']);

    // Update navigation
    navigate(WorkflowManagerNavigationState.DETAIL, {
      execution: updatedExecution,
    });
  }

  // Radio group for selecting when to start
  const { getRadioProps } = useRadioGroup({
    name: 'whenToStart',
    defaultValue: defaultWhenToStart,
    onChange: (value: WhenToStart) => {
      setWhenToStart(value);
      if (value === WhenToStart.NOW) {
        setStartDate('');
      } else if (
        value === WhenToStart.SPECIFIC &&
        !startDate &&
        execution?.scheduled_at
      ) {
        // Only reset the date if we have a scheduled execution and no date set yet
        setStartDate(
          new Date(execution.scheduled_at * 1000).toISOString().slice(0, 16),
        );
      }
    },
  });

  // Handle form submission
  const handleSubmit = useCallback(() => {
    if (isReschedule) {
      reschedule();
    } else {
      start();
    }
  }, [isReschedule, reschedule, start]);

  // Handle cancel
  const handleCancel = useCallback(() => {
    navigate(WorkflowManagerNavigationState.DETAIL);
  }, [navigate]);

  // Get appropriate button text based on the current state
  const getButtonText = useCallback(() => {
    if (isReschedule) {
      return whenToStart === WhenToStart.NOW
        ? 'Run Workflow Now'
        : 'Reschedule Workflow';
    } else {
      return whenToStart === WhenToStart.NOW
        ? 'Run Workflow Now'
        : 'Schedule Workflow';
    }
  }, [isReschedule, whenToStart]);

  // Get RadioCard title text based on current state and option
  const getRadioCardTitle = (value: WhenToStart) => {
    if (value === WhenToStart.NOW) {
      return isReschedule ? 'Run Now' : 'Run Now';
    } else {
      return isReschedule ? 'Reschedule Run' : 'Scheduled Run';
    }
  };

  // Get RadioCard description text based on current state and option
  const getRadioCardDescription = (value: WhenToStart) => {
    if (value === WhenToStart.NOW) {
      return 'Workflow starts instantly.';
    } else {
      return isReschedule
        ? 'Reschedule to a future date.'
        : 'Set a future date to run the workflow.';
    }
  };

  // Update footer content
  useEffect(() => {
    setFooterContent(
      <>
        <Button onClick={handleCancel} variant="ghost">
          Cancel
        </Button>
        <Spacer />
        <Button
          onClick={handleSubmit}
          isLoading={isLoadingStart || isLoadingReschedule}
          leftIcon={<Icon as={ArrowRightIcon} boxSize={4} />}
          isDisabled={disabled || isLoadingStart || isLoadingReschedule}
          variant={'primary'}
        >
          {getButtonText()}
        </Button>
      </>,
    );

    return () => setFooterContent(null);
  }, [
    disabled,
    isLoadingStart,
    isLoadingReschedule,
    handleSubmit,
    handleCancel,
    getButtonText,
    setFooterContent,
  ]);

  // Control submit button disabled state
  useEffect(() => {
    setDisabled(whenToStart === WhenToStart.SPECIFIC && !startDate);
  }, [whenToStart, startDate]);

  // Get page title
  const getPageTitle = () => {
    return isReschedule ? 'Reschedule Workflow' : 'Start Workflow';
  };

  // Add this helper function to calculate expected end date
  const getExpectedEndDate = useCallback(() => {
    const startDateTime =
      whenToStart === WhenToStart.NOW
        ? new Date()
        : startDate
        ? new Date(startDate)
        : null;

    if (!startDateTime || !workflow.expected_duration_amount)
      return 'Select a start date to calculate end date.';

    const endDateTime = new Date(startDateTime);
    const amount = workflow.expected_duration_amount;

    switch (workflow.expected_duration_unit) {
      case 'days':
        endDateTime.setDate(endDateTime.getDate() + amount);
        break;
      case 'weeks':
        endDateTime.setDate(endDateTime.getDate() + amount * 7);
        break;
      case 'months':
        endDateTime.setMonth(endDateTime.getMonth() + amount);
        break;
      case 'years':
        endDateTime.setFullYear(endDateTime.getFullYear() + amount);
        break;
      default:
        return 'Duration unit not supported';
    }
    // Convert Date to Unix timestamp (seconds) before passing to displayFormatedDateAndTime
    return displayFormatedDateAndTime(Math.floor(endDateTime.getTime() / 1000));
  }, [
    whenToStart,
    startDate,
    workflow.expected_duration_amount,
    workflow.expected_duration_unit,
  ]);

  return (
    <Stack gap={4}>
      {/* <Text fontSize="2xl">{getPageTitle()}</Text> */}

      <FormControl isRequired>
        <Label mb={2}>Workflow start</Label>
        <SimpleGrid columns={2} gap={2}>
          {[WhenToStart.NOW, WhenToStart.SPECIFIC].map(value => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard
                key={value}
                borderWidth={1}
                px={4}
                py={2}
                color={
                  whenToStart === value
                    ? 'brand.800'
                    : useColorModeValue('neutral.800', 'neutral.200')
                }
                _hover={{
                  bg: whenToStart === value ? 'brand.25' : 'brandSecondary.25',
                  borderColor:
                    whenToStart === value ? 'brand.base' : 'brandSecondary.600',
                }}
                rounded="md"
                transition="all 0.3s ease-out"
                {...radio}
              >
                <HStack alignItems="flex-start">
                  <VStack gap={0} alignItems="flex-start">
                    <Text fontWeight="bold">{getRadioCardTitle(value)}</Text>
                    <Text>{getRadioCardDescription(value)}</Text>
                  </VStack>
                  <Spacer />
                  <Icon
                    as={CheckCircleIcon}
                    color={
                      whenToStart === value
                        ? 'brand.base'
                        : useColorModeValue('neutral.200', 'neutral.800')
                    }
                    boxSize={5}
                    transition="all 0.3s ease-in-out"
                  />
                </HStack>
              </RadioCard>
            );
          })}
        </SimpleGrid>
      </FormControl>

      {whenToStart === WhenToStart.SPECIFIC && (
        <FormControl isRequired>
          <Label mb={2}>Start Date</Label>
          <Input
            type="datetime-local"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            min={new Date().toISOString().slice(0, 16)}
          />
        </FormControl>
      )}
      {workflow.expected_duration_amount ? (
        <>
          <Stack gap={0}>
            <Label mb={2}>Expected Duration</Label>
            <Text>
              {workflow.expected_duration_amount}{' '}
              {workflow.expected_duration_unit}
            </Text>
          </Stack>
          <Stack gap={0}>
            <Label mb={2}>Expected End Date</Label>
            <Text>{getExpectedEndDate()}</Text>
          </Stack>
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
}

export default WorkflowStart;
